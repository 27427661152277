import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState, userAdapter } from './user.state';
import { User } from '@models';

export const USER_STATE_NAME = 'users';
const selectUserState = createFeatureSelector<UserState>(USER_STATE_NAME);
export const userSelector = userAdapter.getSelectors();
export const selectUsers = createSelector(
  selectUserState,
  userSelector.selectAll
);
export const selectUserEntities = createSelector(
  selectUserState,
  userSelector.selectEntities
);
export const selectUserLoading = createSelector(
  selectUserState,
  state => state.isLoading
);
export const selectUserById = (id: User['id']) =>
  createSelector(selectUserEntities, users => (users ? users[id] : null));
