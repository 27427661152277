import { User } from '@models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface UserState extends EntityState<User> {
  isLoading: boolean;
}
export const userAdapter = createEntityAdapter<User>();
export const UserInitialState: UserState = userAdapter.getInitialState({
  isLoading: false,
});
