import { ErrorProp, Organization, User } from '@models';
import { Update } from '@ngrx/entity';
import { createActionGroup, props } from '@ngrx/store';

export const userActions = createActionGroup({
  source: '[User page]',
  events: {
    'Update User': props<{
      data: Partial<User>;
      id: User['id'];
      organizationId: Organization['id'];
      ref?: string;
      message?: string;
    }>(),
    'Update User Success': props<{ data: Update<User> }>(),
    'Delete User': props<{
      id: User['id'];
      organizationId: Organization['id'];
    }>(),
    'Delete User Success': props<{ id: string }>(),
    'Create User': props<{ user: User; ref?: string }>(),
    'Create User Success': props<{ user: User }>(),
    'Get Users': props<{
      organizationId: Organization['id'];
      filter?: Record<string, unknown>;
    }>(),
    'Get Users Success': props<{ users: User[] }>(),
    'Get Users Failed': props<{ error: string }>(),
    'handle failed': props<{ error: ErrorProp }>(),
    'update loading': props<{ isLoading: boolean }>(),
  },
});
